


















































































































































































































































import { defineComponent, ref, computed, watch, onMounted, toRefs } from "@vue/composition-api";
import {
  IInviteMemberModel,
  InviteMemberModel,
  IRemoveMemberModel,
  ISetMemberPermissionsModel,
  IUpdateNameModel,
  OrganizationMemberModel,
  OrganizationMemberStatus,
  OrganizationModel,
  OrganizationPermissionType,
  OrganizationsClient,
  RemoveMemberModel,
  SetMemberPermissionsModel,
  UpdateNameModel
} from "@/api/OtiumAppApi";
import { useUserStore } from "@/stores/userStore";
import OtiumBasePage from "../OtiumBasePage.vue";

export default defineComponent({
  name: "Organization",
  components: {
    OtiumBasePage
  },
  props: {},
  setup(props, { root, refs }) {
    const organization = ref<OrganizationModel | null>(null);

    const organizationsClient = new OrganizationsClient();
    const userStore = useUserStore();

    async function loadOrganization() {
      organization.value = null;
      if (userStore.userProfile?.adminOrganizationId != null) {
        organization.value = await organizationsClient.getOrganization(
          userStore.userProfile.adminOrganizationId
        );
      }
    }
    onMounted(loadOrganization);

    const tableHeaders = ref([
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Status", value: "status" },
      { text: "", value: "actions", align: "end", sortable: false }
    ]);
    const tableOrganizationMembers = computed(
      () =>
        organization.value?.members.map((member) => ({
          memberId: member.memberId,
          accountId: member.accountId,
          name: member.fullName,
          email: member.email ?? "",
          status:
            member.accountId == userStore.userProfile?.accountId
              ? "Admin"
              : member.memberStatus == OrganizationMemberStatus.Accepted
              ? "Member"
              : member.memberStatus == OrganizationMemberStatus.Suspended
              ? "Suspended"
              : "Pending",
          statusEnum: member.memberStatus
        })) ?? []
    );

    const requiredRule = (v: string) => (v != undefined && v.trim() == "" ? "Required" : true);

    const showInviteDialog = ref(false);
    const invite = ref<IInviteMemberModel | null>(null);
    function openInviteDialog() {
      if (organization.value == null) return;
      invite.value = {
        organizationId: organization.value.organizationId,
        email: "",
        firstName: "",
        lastName: ""
      };
      showInviteDialog.value = true;
    }
    function closeInviteDialog() {
      (refs["inviteForm"] as HTMLFormElement).reset();
      showInviteDialog.value = false;
    }
    const isInviteFormValid = ref(false);
    const sendingInvite = ref(false);
    async function sendInvite() {
      (refs["inviteForm"] as HTMLFormElement).validate();
      if (invite.value == null || !isInviteFormValid.value) return;

      sendingInvite.value = true;
      await organizationsClient.inviteMember(new InviteMemberModel(invite.value));
      await loadOrganization();

      showInviteDialog.value = false;
      sendingInvite.value = false;
      (refs["inviteForm"] as HTMLFormElement).reset();
    }

    const showRemoveDialog = ref(false);
    const removingMemberId = ref<string | null>(null);
    function openRemoveDialog(memberId: string) {
      removingMemberId.value = memberId;
      showRemoveDialog.value = true;
    }
    function closeRemoveDialog() {
      showRemoveDialog.value = false;
      removingMemberId.value = null;
    }
    const removingMember = ref(false);
    async function removeMember() {
      if (organization.value == null || removingMemberId.value == null) return;

      removingMember.value = true;

      const model: IRemoveMemberModel = {
        organizationId: organization.value.organizationId,
        organizationMemberId: removingMemberId.value
      };
      await organizationsClient.removeMember(new RemoveMemberModel(model));
      await loadOrganization();

      showRemoveDialog.value = false;
      removingMember.value = false;
    }

    const permissions = ref([
      {
        enumValue: OrganizationPermissionType.CanCreateProject,
        description: "Can Create Project",
        hasPermission: false
      },
      {
        enumValue: OrganizationPermissionType.CanCreateTemplate,
        description: "Can Create Template",
        hasPermission: false
      }
    ]);
    const showEditPermissionsDialog = ref(false);
    const editingPermissionsUser = ref<OrganizationMemberModel | null>(null);
    function openPermissionsDialog(index: number) {
      editingPermissionsUser.value = organization.value?.members[index] ?? null;

      permissions.value.forEach((permission) => {
        permission.hasPermission =
          editingPermissionsUser.value?.permissions.includes(permission.enumValue) ?? false;
      });

      showEditPermissionsDialog.value = true;
    }
    const savingPermissions = ref(false);
    async function savePermissions() {
      if (
        organization.value == null ||
        editingPermissionsUser.value == null ||
        editingPermissionsUser.value.accountId == null
      )
        return;
      savingPermissions.value = true;

      const model: ISetMemberPermissionsModel = {
        organizationId: organization.value.organizationId,
        accountId: editingPermissionsUser.value.accountId,
        newPermissions: permissions.value.filter((p) => p.hasPermission).map((p) => p.enumValue)
      };
      await organizationsClient.setMemberPermissions(new SetMemberPermissionsModel(model));

      await loadOrganization();

      editingPermissionsUser.value = null;
      showEditPermissionsDialog.value = false;
      savingPermissions.value = false;
    }
    function closeEditPermissionsDialog() {
      editingPermissionsUser.value = null;
      showEditPermissionsDialog.value = false;
    }

    /**
     * Edit name dialog
     */
    const isEditNameFormValid = ref(false);
    const isEditNameDialogOpen = ref(false);
    const newName = ref("");
    function openEditNameDialog() {
      newName.value = organization.value?.name ?? "";
      isEditNameDialogOpen.value = true;
    }

    function updateName() {
      (refs["editNameForm"] as HTMLFormElement).validate();
      if (!isEditNameFormValid.value) return;

      if (organization.value != null) {
        let updateModel: IUpdateNameModel = {
          organizationId: organization.value?.organizationId ?? "",
          name: newName.value
        };
        organizationsClient.updateName(new UpdateNameModel(updateModel));
        organization.value.name = newName.value;
        newName.value = "";
        isEditNameDialogOpen.value = false;
      }
    }

    return {
      userStore,
      organization,

      tableHeaders,
      tableOrganizationMembers,
      OrganizationMemberStatus,

      requiredRule,

      showInviteDialog,
      openInviteDialog,
      closeInviteDialog,
      isInviteFormValid,
      sendingInvite,
      invite,
      sendInvite,

      showRemoveDialog,
      removingMemberId,
      openRemoveDialog,
      closeRemoveDialog,
      removingMember,
      removeMember,

      permissions,
      showEditPermissionsDialog,
      editingPermissionsUser,
      openPermissionsDialog,
      savePermissions,
      savingPermissions,
      closeEditPermissionsDialog,
      openEditNameDialog,
      isEditNameDialogOpen,
      updateName,
      newName,
      isEditNameFormValid
    };
  }
});
